<template>
  <div id="batch-boxes-list" class="card card-customize p-0 mb-0">
    <div class="card-body p-0">
      <div class="d-flex flex-row p-1 bg-success">
        <h5 class="mb-0 alig-self-center">
          Boxes
        </h5>
      </div>
      <dx-tree-list
        id="shipmentItemBoxes"
        ref="shipmentItemBoxesRef"
        :data-source="dataSource"
        key-expr="id"
        parent-id-expr="parentId"
        data-structure="plain"
        :expanded-row-keys="expandedRowKeys"
        :show-column-headers="false"
        :show-row-lines="true"
        :show-borders="false"
        :show-column-lines="false"
        :column-auto-width="true"
        :height="boxesStyle.innerScroolHeight"
        @row-prepared="rowPrepared"
      >
      <dx-scrolling mode="standard" :use-native="false" show-scrollbar="always" :scroll-by-content="true" column-rendering-mode="virtual" />
        <dx-column
          data-field="name"
          :allow-sorting="false"
          cell-template="nameCellTemplate"
          width="100%"
        />
        <template #nameCellTemplate="{ data }">
          <div v-if="data.data.type === batchConst">
              <b-icon icon="pip-fill" class="mr-half" /> {{ data.value }} ({{ data.data.destCode }})
          </div>
          <div v-else-if="data.data.type === boxConst">
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="cursor-pointer">
                <b-icon icon="box" />
                {{ data.value }}
              </div>
              <div class="text-white">
                {{ data.data.totalSalePrice }}
              </div>
            </div>
          </div>
          <div v-else-if="data.data.type === itemConst" class="cursor-pointer" @click="emitSetSelectedFnsku(data.value)">
            <b-icon icon="tag-fill" />
            {{ data.value }}
          </div>
          <div v-else>
            {{ data.value }}
          </div>
        </template>
        <dx-column name="printBarcode" type="buttons" width="32" alignment="center" :allow-resizing="false" :allow-sorting="false">
          <dx-button
            type="default"
            icon="print"
            hint="Print 2D Content Barcode"
            css-class="text-warning button-cursor"
            :visible="isEnableDeleted"
            :on-click="onPrintBarcode"
          />
        </dx-column>
        <dx-column name="print" type="buttons" width="32" alignment="center" :allow-resizing="false" :allow-sorting="false">
          <dx-button
            type="default"
            icon="print"
            hint="Print Box Label"
            css-class="text-primary button-cursor"
            :visible="isEnableDeleted"
            :on-click="onPrintBoxLabel"
          />
        </dx-column>
        <dx-column
          data-field="totalQuantity"
          cell-template="totalQuantityTemplate"
          :allow-sorting="false"
          :allow-resizing="false"
          alignment="center"
          width="40"
        />
        <dx-column
          data-field="quantity"
          cell-template="itemQuantityTemplate"
          :allow-sorting="false"
          :allow-resizing="false"
          alignment="center"
          width="40"
        />
        <dx-column name="actions" type="buttons" width="32" :allow-resizing="false" :allow-sorting="false">
          <dx-button
            type="success"
            icon="add"
            hint="Add Box"
            css-class="text-success button-cursor"
            :visible="isEnableAdd"
            :on-click="addBox"
          />
          <dx-button
            type="danger"
            icon="trash"
            hint="Remove Box"
            css-class="text-danger button-cursor"
            :visible="isEnableDeleted"
            :on-click="removeBox"
          />
        </dx-column>
        <template #itemQuantityTemplate="{ data }">
          <div v-if="data.data.type === batchConst" v-b-tooltip.hover="{ variant: 'info', title: 'Box Count'}" class="badge badge-warning w-100">
            {{ data.value }}
          </div>
          <div v-else-if="data.value > 0 && data.data.type === boxConst" v-b-tooltip.hover="{ variant: 'info', title: 'SKU Count'}" class="badge badge-primary w-100">
            {{ data.value }}
          </div>
          <div v-else-if="data.data.type === itemConst" v-b-tooltip.hover="{ variant: 'info', title: 'Item Quantity'}" class="badge badge-success w-100">
            {{ data.value }}
          </div>
          <div v-else class="badge badge-primary w-100">
            {{ data.value }}
          </div>
        </template>
        <template #totalQuantityTemplate="{ data }">
          <div v-if="data.value > 0 && data.data.type === boxConst" v-b-tooltip.hover="{ variant: 'info', title: 'Total Count'}" class="badge badge-success w-100">
            {{ data.value }}
          </div>
          <div v-else-if="data.data.type === boxConst" v-b-tooltip.hover="{ variant: 'info', title: 'Add all batch items into this box'}" class="w-100">
            <b-button
              variant="flat-success"
              class="btn-action-xs"
              @click="assignAllBatchParentItemToBox(data.data.parentId)"
            >
              <b-icon scale="1" icon="box-seam" />
            </b-button>
          </div>
        </template>
      </dx-tree-list>
    </div>
    <!-- #region Add Box Popup-->
    <dx-util-popup
      ref="popupAddBoxPopupRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      :width="640"
      :height="420"
      content-template="popup-content"
      @shown="onShownAddBoxPopup"
      @hidden="onHiddenAddBoxPopup"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveAddBoxPopupButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelAddBoxPopupButtonOptions" />
      <template #popup-content>
        <div class="d-flex flex-column">
          <div>
            <p>
              Dimensions are optional. You can set or update them later in the shipping step.
            </p>
          </div>
          <div class="mb-1">
            <dx-util-radio-group
              v-model="boxModel.applyBoxDimension"
              :data-source="applyBoxDimensions"
              display-expr="text"
              value-expr="value"
              layout="horizontal"
            />
          </div>
          <div class="mb-1">
            <dx-util-select-box
              id="defined-box-dimensions"
              ref="definedBoxDimensionsRef"
              :value="definedBoxDimensions[0].value"
              :data-source="definedBoxDimensions"
              display-expr="text"
              value-expr="value"
              field-template="field"
              item-template="item"
              @value-changed="onChangeDefinedBoxDimensions"
            >
              <template #field="{data}">
                <div class="d-flex flex-row align-items-center px-1 w-100">
                  <div class="mr-half">
                    <i class="bi" :class="data.value !== 0 ? 'bi-box-seam-fill' : 'bi-box-seam'" style="font-size: 1.2rem;"></i>
                  </div>
                  <div style="display: inline-block; width: 100%;">
                    <dx-util-text-box
                      :value="data.text"
                      :read-only="true"
                      >
                    </dx-util-text-box>
                  </div>
                </div>
              </template>
              <template #item="{data}">
                <div class="d-flex flex-row align-items-center">
                  <div class="mr-half">
                    <i class="bi" :class="data.value !== 0 ? 'bi-box-seam-fill' : 'bi-box-seam'" style="font-size: 1.2rem;"></i>
                  </div>
                  <div>
                    {{ data.text }}
                  </div>
                </div>
              </template>
              </dx-util-select-box>
          </div>
          <div class="d-flex flex-row mb-1">
            <div class="flex-fill mr-1">
              <dx-util-number-box
                v-model="boxModel.length"
                label="Length"
                label-mode="floating"
                height="36px"
                :min="0"
                :max="9999"
                :show-spin-buttons="false"
              />
            </div>
            <div class="flex-fill">
              <dx-util-number-box
                v-model="boxModel.width"
                label="Width"
                label-mode="floating"
                height="36px"
                :min="0"
                :max="9999"
                :show-spin-buttons="false"
              />
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="flex-fill mr-1">
              <dx-util-number-box
                v-model="boxModel.height"
                label="Height"
                label-mode="floating"
                height="36px"
                :min="0"
                :max="9999"
                :show-spin-buttons="false"
              />
            </div>
            <div class="flex-fill">
              <dx-util-number-box
                  v-model="boxModel.weight"
                  label="Weight"
                  label-mode="floating"
                  height="36px"
                  :min="0"
                  :max="9999"
                  :show-spin-buttons="false"
                />
            </div>
          </div>
        </div>
      </template>
    </dx-util-popup>
    <!-- #endregion -->
    <!-- #region Print 2D Content Barcode Popup -->
    <dx-util-popup
      ref="popupPrintBarcodeRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      :title="popupPrintBarcodeTitle"
      :width="480"
      :height="360"
      content-template="popup-content"
      @shown="onShownPopupPrintBarcode"
      @hidden="onHiddenPopupPrintBarcode"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="printBarcodeButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelBarcodeButtonOptions" />
      <template #popup-content>
        <div>
          <dx-util-select-box
            id="printer-select-box"
            v-model="selectedPrinter"
            :data-source="printers"
            display-expr="Name"
            placeholder="Select Printer"
          />
        </div>
      </template>
    </dx-util-popup>
  <!-- #endregion -->
  </div>

</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/require-default-prop */
import DxTreeList, {
  DxColumn,
  DxSearchPanel,
  DxSelection,
  DxRemoteOperations,
  DxScrolling,
} from 'devextreme-vue/tree-list'
import moment from 'moment'
import sumBy from 'lodash/sumBy'
import reduce from 'lodash/reduce'
import { emitter } from '@/libs/bus'
import { FBAEvents } from '@/libs/constants/events.const'
import store from '@/store'
import isNaN from 'lodash/isNaN'
import head from 'lodash/head'
import { currencyFormatter } from '@core/utils/filter'
import { v4 as uuidv4 } from 'uuid'
import usePrinter from '@/libs/printer/print'
import printerVarabiles, { labelTypes } from '@/libs/printer/printer-varaibles'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import { Notify } from '@robustshell/utils/index'
import { LabelParam } from '@/http/models/printer/entity/labelparam'
import useConfirmation from '@/libs/app/confirmation'
import useCurrentUser from '@/libs/app/current-user'
import fbaShipment from '../fbaShipment.mixin'

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxSearchPanel,
    DxSelection,
    DxRemoteOperations,
    DxScrolling,
  },
  mixins: [fbaShipment],
  props: {
    batchId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const {
      selectedPrinter,
      printers,
      pshBase64,
      printStaticLabel,
      printStaticLabelCurrentPrinter,
    } = usePrinter()
    const { pshConfirm } = useConfirmation()
    const { userWarehouseId } = useCurrentUser()
    return {
      selectedPrinter,
      printers,
      pshBase64,
      printStaticLabel,
      printStaticLabelCurrentPrinter,
      userWarehouseId,
      pshConfirm,
    }
  },
  data() {
    return {
      selectedIndex: 0,
      dataSource: [],
      expandedRowKeys: [],
      batchConst: 'BATCH',
      boxConst: 'BOX',
      itemConst: 'ITEM',
      popupPrintBarcodeTitle: '',
      selectedBoxId: null,
      boxModel: {
        id: null,
        applyBoxDimension: true,
        companyId: null,
        storeId: null,
        warehouseId: null,
        batchId: null,
        itemAction: null,
        batchInfo: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
      },
      selectedDefinedBoxDimension: 0,
      definedBoxDimensions: [
        { value: 0, text: 'Defined Box Dimensions' },
        {
          value: 1, text: '12 x 12 x 12', length: 12, width: 12, height: 12,
        },
        {
          value: 2, text: '14 x 14 x 14', length: 14, width: 14, height: 14,
        },
        {
          value: 3, text: '16 x 16 x 16', length: 16, width: 16, height: 16,
        },
        {
          value: 4, text: '18 x 18 x 18', length: 18, width: 18, height: 18,
        },
        {
          value: 5, text: '20 x 20 x 20', length: 20, width: 20, height: 20,
        },
        {
          value: 6, text: '24 x 24 x 24', length: 24, width: 24, height: 24,
        },
      ],
      applyBoxDimensions: [
        { value: true, text: 'Apply dimensions to last box' },
        { value: false, text: 'Apply dimensions to new box' },
      ],
    }
  },
  computed: {
    boxTree() {
      const tree = this.$refs.shipmentItemBoxesRef.instance
      return tree
    },
    headerInfo() {
      return store.getters['fbaShipment/getSelectedBactInfo']
    },
    popupPrintBarcodeInstance() {
      return this.$refs.popupPrintBarcodeRef.instance
    },
    printBarcodeButtonOptions() {
      return {
        text: 'Print',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handlePrintBarcode(e.event)
        },
      }
    },
    cancelBarcodeButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.popupPrintBarcodeInstance.hide()
        },
      }
    },
    popupAddBoxInstance() {
      return this.$refs.popupAddBoxPopupRef.instance
    },
    saveAddBoxPopupButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleAddBoxSavePopup(e.event)
        },
      }
    },
    cancelAddBoxPopupButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.popupAddBoxInstance.hide()
        },
      }
    },
  },
  watch: {
    batchId: {
      immediate: true,
      async handler(current, previous) {
        if (current && current > 0) {
          await this.getBatchBoxes(current)
        }
      },
    },
  },
  mounted() {
    emitter.on(FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.event, async payload => {
      if (payload === FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.action) {
        await this.getBatchBoxes(this.batchId)
      }
    })
  },
  methods: {
    async assignAllBatchParentItemToBox(batchId) {
      this.$swal({
        title: 'Confirm Item Box Assignment',
        html: `
          <div class="d-flex  justify-content-start px-5">
            <div class="w-100 d-flex flex-row py-half">
              Are you sure you want to assign all items in this shipment batch to a single box.
            </div>
          </div>
        `,
        width: '25vw',
        showCancelButton: true,
        confirmButtonText: 'Assign',
        customClass: {
          confirmButton: 'btn btn-success w-25',
          cancelButton: 'btn btn-outline-danger ml-1 w-25',
          content: 'px-0',
          htmlContainer: 'mx-0',
        },
        buttonsStyling: false,
        icon: 'success',
      }).then(result => {
        if (result.isConfirmed) {
          fbaShipmentService.assignAllBatchParentItemToBox(batchId)
            .then(() => {
              this.$emit('emit-update-shipping-item-content')
            })
        }
      })
    },
    rowPrepared(e) {
      if (e.rowType === 'data') {
        if (e.level === 0) {
          e.rowElement.style.color = '#f39c12'
        }
        if (e.level === 1) {
          e.rowElement.style.color = '#27ae60'
        }
      }
    },
    isEnableAdd(e) {
      return e.row.level === 0
    },
    isEnableDeleted(e) {
      return e.row.level === 1
    },
    isShowItemQuantity(e) {
      return e.data.parentId
    },
    async getBatchBoxes(batchId) {
      const parsed = parseInt(batchId, 10)
      if (isNaN(parsed)) { return }
      const storeBoxes = []
      const result = await fbaShipmentService.getAllBoxesByBatchId(parsed)
      this.dataSource.splice(0, this.dataSource.length)
      this.expandedRowKeys.splice(0, this.expandedRowKeys.length)
      const { data } = result
      data.forEach(batchItem => {
        const { boxes, parentId, destCode } = batchItem
        const batch = {
          id: parentId,
          parentId: null,
          name: batchItem.shipmentId,
          destCode: destCode,
          type: batchItem.type,
          quantity: boxes.length,
        }
        if (boxes.length <= 10) {
          this.expandedRowKeys.push(parentId)
        }
        this.dataSource.push(batch)
        boxes.forEach(batchBox => {
          const { items } = batchBox
          const headItem = head(items)
          const total = sumBy(items, 'quantity')

          const totalSalePrice = reduce(items, (sum, item) => {
            if (item && item.quantity != null && item.salePrice != null) {
              return sum + (item.quantity * item.salePrice)
            }
            return sum
          }, 0)

          const box = {
            id: batchBox.id,
            parentId: parentId,
            name: `B${batchBox.boxOrder}`,
            type: batchBox.type,
            destCode: destCode,
            quantity: headItem ? items.length : 0,
            totalQuantity: total,
            totalSalePrice: currencyFormatter(totalSalePrice),
          }
          this.dataSource.push(box)
          storeBoxes.push(box)
          items.forEach(boxItem => {
            if (boxItem) {
              const item = {
                id: uuidv4(),
                parentId: box.id,
                name: boxItem.fnsku,
                type: boxItem.type,
                quantity: boxItem.quantity,
              }
              this.dataSource.push(item)
            }
          })
        })
        store.dispatch('batchBoxes/setBatchBoxes', storeBoxes)
      })

      this.boxTree.repaint()
    },
    async addBox(e) {
      this.popupAddBoxInstance.show()
      const { data } = e.row
      this.boxModel.id = null
      this.boxModel.companyId = this.headerInfo.companyId
      this.boxModel.storeId = this.headerInfo.storeId
      this.boxModel.warehouseId = this.userWarehouseId
      this.boxModel.batchId = data.id
      this.boxModel.itemAction = this.$enums.ItemActionTypeEnum.fba.value
      this.boxModel.batchInfo = data
    },
    onShownAddBoxPopup(e) {
      this.popupAddBoxInstance.option('title', `Add Box to ${this.boxModel.batchInfo.name}`)
    },
    onHiddenAddBoxPopup(e) {
      const definedBoxDimensions = this.$refs.definedBoxDimensionsRef.instance
      definedBoxDimensions.option('value', this.definedBoxDimensions[0].value)
      this.popupAddBoxInstance.option('title', '')
      this.boxModel = {
        id: null,
        companyId: null,
        storeId: null,
        warehouseId: null,
        batchId: null,
        itemAction: null,
        batchInfo: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        applyBoxDimension: true,
      }
    },
    onChangeDefinedBoxDimensions(e) {
      const { value } = e
      if (value === 0) {
        this.boxModel.length = 0
        this.boxModel.width = 0
        this.boxModel.height = 0
      } else {
        const selected = this.definedBoxDimensions.find(x => x.value === value)
        this.boxModel.length = selected.length
        this.boxModel.width = selected.width
        this.boxModel.height = selected.height
      }
    },
    async handleAddBoxSavePopup(e) {
      try {
        const box = {
          id: this.boxModel.id,
          length: this.boxModel.length,
          width: this.boxModel.width,
          height: this.boxModel.height,
          weight: this.boxModel.weight,
          batchId: this.boxModel.batchId,
          companyId: this.boxModel.companyId,
          storeId: this.boxModel.storeId,
          warehouseId: this.boxModel.warehouseId,
          itemAction: this.boxModel.itemAction,
        }

        if (this.boxModel.applyBoxDimension) {
          const batchBoxs = this.dataSource?.filter(x => x.parentId === box.batchId)
          if (batchBoxs.length > 0) {
            const lastAddedBox = batchBoxs?.reduce((prev, current) => (prev.id > current.id ? prev : current))
            box.id = lastAddedBox.id
          }
        }

        const result = await fbaShipmentService.createBox(box)
        const addedBox = {
          id: result.data.id,
          name: `B${result.data.boxOrder}`,
          parentId: result.data.batchId,
          quantity: 0,
        }
        const payload = {
          barcodeLabelText: `${this.boxModel.batchInfo.destCode}-${addedBox.name}`,
        }
        emitter.emit(FBAEvents.PRINT_CODE_LABEL_CONDITIONAL.event, payload)
        store.dispatch('batchBoxes/addBatchBoxes', addedBox)
        await this.getBatchBoxes(this.batchId)
      } catch (error) {
        Notify.error(error)
      }
      this.popupAddBoxInstance.hide()
    },
    async removeBox(e) {
      const { data } = e.row
      const confirm = await this.pshConfirm(
        'Delete Box',
        'Are you sure you want to delete this box? This action cannot be undone.',
      )
      if (confirm) {
        try {
          const result = await fbaShipmentService.deleteFBABatchBoxId(data.id)
          store.dispatch('batchBoxes/clearBatchBoxes', null)
          await this.getBatchBoxes(this.batchId)
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    async onPrintBoxLabel(e) {
      const { data } = e.row
      const params = []
      params.push(new LabelParam(printerVarabiles.BOX_NAME.value, `${data.destCode}-${data.name}`))
      const payload = {
        params: params,
        labelName: labelTypes.BOX_NAME.value,
      }
      await this.printStaticLabelCurrentPrinter(payload)
    },
    emitSetSelectedFnsku(payload) {
      emitter.emit(FBAEvents.BOX_FILTER_SELECTED_FNSKU.event, payload)
    },
    getUserData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    // #region Event handlers for print 2D content of box
    onPrintBarcode(e) {
      const { data } = e.row
      this.selectedBoxId = data.id
      this.popupPrintBarcodeTitle = `${data.name}`
      if (data.quantity > 0) {
        this.popupPrintBarcodeInstance.show()
      } else {
        Notify.warning('No item in box')
      }
    },
    createPrinterParams(data) {
      const params = []
      params.push(new LabelParam(printerVarabiles.SHIPPING_PLAN_NAME.value, data.shippingPlanName))
      params.push(new LabelParam(printerVarabiles.CREATED_AT.value, moment(data.createdAt).format('YYYY-MM-DD HH:mm')))
      params.push(new LabelParam(printerVarabiles.BATCH_AND_BOX_NAME.value, data.batchAndBoxName))
      params.push(new LabelParam(printerVarabiles.ITEM_QTY.value, data.itemQty))
      params.push(new LabelParam(printerVarabiles.SKU_QTY.value, data.skuQty))
      params.push(new LabelParam(printerVarabiles.BARCODE_CONTENT.value, data.barcode))
      params.push(new LabelParam(printerVarabiles.CURRENT_DATE.value, moment(new Date()).format('YYYY-MM-DD')))
      params.push(new LabelParam(printerVarabiles.PSH_BASE64_CONST.value, this.pshBase64))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE1.value, data.shipFrom[0]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE2.value, data.shipFrom[1]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE3.value, data.shipFrom[2]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE4.value, data.shipFrom[3]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE5.value, data.shipFrom[4]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE6.value, data.shipFrom[5]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE1.value, data.shipTo[0]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE2.value, data.shipTo[1]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE3.value, data.shipTo[2]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE4.value, data.shipTo[3]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE5.value, data.shipTo[4]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE6.value, data.shipTo[5]))
      return params
    },
    async handlePrintBarcode(e) {
      if (!this.selectedPrinter.VendorName) {
        Notify.warning('Select a printer')
        return
      }
      const result = await fbaShipmentService.getBoxContentFor2DBarcode(this.selectedBoxId)
      try {
        const data = result.data
        const params = this.createPrinterParams(data)
        this.printStaticLabel(this.selectedPrinter.VendorName, this.selectedPrinter.Address, labelTypes.CONTENT_BARCODE_LABEL.value, params)
        this.popupPrintBarcodeInstance.hide()
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    onShownPopupPrintBarcode(e) {
      this.popupPrintBarcodeTitle = `Box ${this.popupPrintBarcodeTitle} - 2D Content Barcode`
    },
    onHiddenPopupPrintBarcode(e) {
      this.popupPrintBarcodeTitle = ''
      this.selectedBoxId = null
    },
  },
}
</script>
<style lang="scss">
  .button-cursor {
    &:hover {
      cursor: pointer;
    }
  }
</style>
